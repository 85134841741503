<script setup>
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { ref, onMounted, defineProps, defineEmits } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const emits = defineEmits(["addToCart", "closeDialog"]);
const router = useRouter();
const token = ref(null);
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    isOpen: {
        type: Boolean,
        required: true,
    },
});

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

const openDialog = () => {
    emits("closeDialog");
};
</script>

<template>
    <Dialog as="div" class="tw-fixed tw-inset-0 tw-z-[99] tw-overflow-y-auto" :open="isOpen" @close="openDialog">
        <div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen">
            <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-black tw-opacity-30" />
            <div
                class="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all tw-max-w-lg tw-w-full">
                <DialogTitle class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 tw-p-4 tw-border-b">Filter
                    Lokasi</DialogTitle>
                <div class="tw-p-4 tw-flex tw-gap-4 tw-items-center">
                    <div class="tw-grid tw-divide-y tw-divide-neutral-200 tw-w-full">
                        <div class="tw-py-1">
                            <details class="tw-group">
                                <summary
                                    class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">
                                    <span class="tw-text-black tw-text-lg">Surabaya</span>
                                    <font-awesome-icon icon="fa-solid fa-angle-down" class="tw-text-black icon-lg" />
                                </summary>
                                <fieldset class="tw-mt-4">
                                    <legend class="tw-sr-only">Checkboxes</legend>
                                    <div class="tw-space-y-2">
                                        <label for="Option1"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option1" />
                                            </div>
                                            <div>
                                                <strong class="tw-font-medium tw-text-gray-900">Sukolilo</strong>
                                            </div>
                                        </label>
                                        <label for="Option2"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option2" />
                                            </div>
                                            <div>
                                                <strong class="tw-font-medium tw-text-gray-900">Rungkut</strong>
                                            </div>
                                        </label>
                                        <label for="Option3"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option3" />
                                            </div>
                                            <div>
                                                <strong
                                                    class="tw-text-pretty tw-font-medium tw-text-gray-900">Wonorejo</strong>
                                            </div>
                                        </label>
                                    </div>
                                </fieldset>
                            </details>
                        </div>
                        <div class="tw-py-5">
                            <details class="tw-group">
                                <summary
                                    class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">
                                    <span class="tw-text-black tw-text-lg">Sidoarjo</span>
                                    <font-awesome-icon icon="fa-solid fa-angle-down" class="tw-text-black icon-lg" />
                                </summary>
                                <fieldset class="tw-mt-4">
                                    <legend class="tw-sr-only">Checkboxes</legend>
                                    <div class="tw-space-y-2">
                                        <label for="Option1"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option1" />
                                            </div>
                                            <div>
                                                <strong class="tw-font-medium tw-text-gray-900">Sukodono</strong>
                                            </div>
                                        </label>
                                        <label for="Option2"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option2" />
                                            </div>
                                            <div>
                                                <strong class="tw-font-medium tw-text-gray-900">Wage</strong>
                                            </div>
                                        </label>
                                        <label for="Option3"
                                            class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                            <div class="tw-flex tw-items-center">
                                                &#8203;
                                                <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                                    id="Option3" />
                                            </div>
                                            <div>
                                                <strong
                                                    class="tw-text-pretty tw-font-medium tw-text-gray-900">Candi</strong>
                                            </div>
                                        </label>
                                    </div>
                                </fieldset>
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>