<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
import LocationModal from "@/components/LocationModal.vue";

const router = useRouter();
const token = ref(null);
const isOpen = ref(null);
const isDropdownCategory = ref(false);
const isFilterCategory = ref(false);
const isFilterPrice = ref(false);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function openLocationModal() {
    isOpen.value = "location";
}

const closeAddOnModal = () => {
    isOpen.value = "main";
};

function handleFilterCategory() {
    isFilterCategory.value = !isFilterCategory.value;
    isFilterPrice.value = false;
}

function handleFilterPrice() {
    isFilterPrice.value = !isFilterPrice.value;
    isFilterCategory.value = false;
}

function openDropdown() {
    isDropdownCategory.value = !isDropdownCategory.value;
}

function gotoDetail() {
    router.push('/outlet-detail');
}

function gotoAuth() {
    router.push('/auth');
}

function gotoHome() {
    router.push('/');
}
</script>

<template>
    <LocationModal :item="[]" @close-dialog="closeAddOnModal" :isOpen="isOpen == 'location'"></LocationModal>
    <div class="tw-flex tw-flex-col">
        <nav
            class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow">
            <button @click="gotoHome"
                class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4">
                <img src="@/assets/caricatering.png" alt="Logo" class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12">
                <a href="#"
                    class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg">CariCatering.id</a>
            </button>
            <div class="tw-flex tw-items-center tw-space-x-4">
                <div class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
                    <button @click="openDropdown" class="tw-flex tw-items-center tw-text-gray-700">
                        Cari Apa?
                        <span class="tw-transition" :class="isDropdownCategory ? 'tw-rotate-180' : ''">
                            <svg class="tw-ml-1 tw-w-4 tw-h-4" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd"
                                    d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </button>
                    <div class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
                        :class="isDropdownCategory ? '' : 'tw-hidden'" style="z-index: 1;">
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 1</a>
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 2</a>
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 3</a>
                    </div>
                </div>
                <a href="#" class="tw-text-gray-700 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Pusat Bantuan</a>
                <a href="#" class="tw-text-gray-700 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Syarat dan
                    Ketentuan</a>
                <button @click="gotoAuth"
                    class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded">
                    Masuk
                </button>
            </div>
        </nav>

        <div
            class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4 tw-py-4 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32">
            <div class="tw-h-fit tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300">
                <div
                    class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-px-4 tw-py-2 tw-text-gray-900 tw-transition">
                    <span class="tw-text-sm tw-font-medium">Lokasi</span>
                    <button @click="openLocationModal"
                        class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-rounded-full">Pilih</button>
                </div>
            </div>
            <div class="tw-relative tw-h-fit tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300">
                <div @click="handleFilterCategory"
                    class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition">
                    <span class="tw-text-sm tw-font-medium">Layanan</span>
                    <span class="tw-transition" :class="isFilterCategory ? 'tw-rotate-180' : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="tw-h-4 tw-w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </span>
                </div>
                <div class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full" style="z-index: 1;"
                    v-show="isFilterCategory">
                    <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span class="tw-text-sm tw-text-gray-700"> 0 Selected </span>
                        <button type="button" class="tw-text-sm tw-text-gray-900 tw-underline tw-underline-offset-4">
                            Reset
                        </button>
                    </header>
                    <ul class="tw-space-y-1 tw-border-t tw-border-gray-200 tw-p-4">
                        <li>
                            <label for="FilterInStock" class="tw-inline-flex tw-items-center tw-gap-2">
                                <input type="checkbox" id="FilterInStock"
                                    class="tw-size-5 tw-rounded tw-border-gray-300" />

                                <span class="tw-text-sm tw-font-medium tw-text-gray-700"> In Stock (5+) dsadsa dasdas
                                    dsadsa </span>
                            </label>
                        </li>
                        <li>
                            <label for="FilterPreOrder" class="tw-inline-flex tw-items-center tw-gap-2">
                                <input type="checkbox" id="FilterPreOrder"
                                    class="tw-size-5 tw-rounded tw-border-gray-300" />

                                <span class="tw-text-sm tw-font-medium tw-text-gray-700"> Pre Order (3+) </span>
                            </label>
                        </li>
                        <li>
                            <label for="FilterOutOfStock" class="tw-inline-flex tw-items-center tw-gap-2">
                                <input type="checkbox" id="FilterOutOfStock"
                                    class="tw-size-5 tw-rounded tw-border-gray-300" />

                                <span class="tw-text-sm tw-font-medium tw-text-gray-700"> Out of Stock (10+) </span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tw-relative tw-h-fit tw-w-full tw-rounded tw-border tw-border-gray-300">
                <div @click="handleFilterPrice"
                    class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition">
                    <span class="tw-text-sm tw-font-medium">Harga</span>
                    <span class="tw-transition" :class="isFilterPrice ? 'tw-rotate-180' : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="tw-h-4 tw-w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </span>
                </div>
                <div class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full" style="z-index: 1;"
                    v-show="isFilterPrice">
                    <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span class="tw-text-sm tw-text-gray-700"> The highest price is $600 </span>
                        <button type="button" class="tw-text-sm tw-text-gray-900 tw-underline tw-underline-offset-4">
                            Reset
                        </button>
                    </header>
                    <div class="tw-border-t tw-border-gray-200 tw-p-4">
                        <div class="tw-flex tw-justify-between tw-gap-4">
                            <label for="FilterPriceFrom" class="tw-flex tw-items-center tw-gap-2">
                                <span class="tw-text-sm tw-text-gray-600">Rp</span>
                                <input type="number" id="FilterPriceFrom" placeholder="From"
                                    class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm" />
                            </label>
                            <label for="FilterPriceTo" class="tw-flex tw-items-center tw-gap-2">
                                <span class="tw-text-sm tw-text-gray-600">Rp</span>
                                <input type="number" id="FilterPriceTo" placeholder="To"
                                    class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p class="tw-pt-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-text-black tw-text-xl">Hasil Pencarian
        </p>
        <div
            class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-pb-12">
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="gotoDetail">
                <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                    <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                        <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                            alt="avatar">
                        <div class="tw-px-6 tw-py-4">
                            <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                Parties</p>
                            <div
                                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                Catering</div>
                            <div
                                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering">
                                Rp30.000 - Rp100.000
                            </div>
                            <div class="tw-flex tw-mt-2 tw-text-gray-400">
                                <h1 class="tw-text-sm">Sukolilo, Surabaya</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>