<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function handleSearch() {
    router.push('/home');
}
</script>

<template>
    <div class="tw-flex tw-flex-col tw-px-4 sm:tw-px-4 md:tw-px-52 lg:tw-px-52 tw-mt-4">
        <div class="tw-flex tw-flex-row tw-items-center">
            <font-awesome-icon class="tw-text-black tw-pr-5" size="2xl" :icon="['fas', 'chevron-left']" />
            <div class="tw-basis-11/12 tw-mr-5">
                <input class="tw-w-full tw-rounded-lg tw-border-2 tw-border-gray-400 tw-p-3 tw-text-sm"
                    placeholder="Cari Catering Anda" type="text" id="search" />
            </div>
            <div class="tw-basis-1/12">
                <button @click="handleSearch"
                    class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-indigo-600 tw-rounded-lg hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80">
                    <font-awesome-icon class="tw-text-white tw-pr-1" size="lg" :icon="['fas', 'magnifying-glass']" />
                    <span class="tw-mx-1">Cari</span>
                </button>
            </div>
        </div>

        <p class="tw-mt-5 tw-text-black tw-text-xl">Area Mitra Catering Kami</p>
        <div class="tw-grid tw-divide-y tw-divide-neutral-200 tw-w-full">
            <div class="tw-py-5">
                <details class="tw-group">
                    <summary
                        class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">
                        <span class="tw-text-black tw-text-lg">Surabaya</span>
                        <font-awesome-icon icon="fa-solid fa-angle-down" class="tw-text-black icon-lg" />
                    </summary>
                    <fieldset class="tw-mt-4">
                        <legend class="tw-sr-only">Checkboxes</legend>
                        <div class="tw-space-y-2">
                            <label for="Option1"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option1" />
                                </div>
                                <div>
                                    <strong class="tw-font-medium tw-text-gray-900">Sukolilo</strong>
                                </div>
                            </label>
                            <label for="Option2"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option2" />
                                </div>
                                <div>
                                    <strong class="tw-font-medium tw-text-gray-900">Rungkut</strong>
                                </div>
                            </label>
                            <label for="Option3"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option3" />
                                </div>
                                <div>
                                    <strong class="tw-text-pretty tw-font-medium tw-text-gray-900">Wonorejo</strong>
                                </div>
                            </label>
                        </div>
                    </fieldset>
                </details>
            </div>
            <div class="tw-py-5">
                <details class="tw-group">
                    <summary
                        class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">
                        <span class="tw-text-black tw-text-lg">Sidoarjo</span>
                        <font-awesome-icon icon="fa-solid fa-angle-down" class="tw-text-black icon-lg" />
                    </summary>
                    <fieldset class="tw-mt-4">
                        <legend class="tw-sr-only">Checkboxes</legend>
                        <div class="tw-space-y-2">
                            <label for="Option1"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option1" />
                                </div>
                                <div>
                                    <strong class="tw-font-medium tw-text-gray-900">Sukodono</strong>
                                </div>
                            </label>
                            <label for="Option2"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option2" />
                                </div>
                                <div>
                                    <strong class="tw-font-medium tw-text-gray-900">Wage</strong>
                                </div>
                            </label>
                            <label for="Option3"
                                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50">
                                <div class="tw-flex tw-items-center">
                                    &#8203;
                                    <input type="checkbox" class="tw-size-4 tw-rounded tw-border-gray-300"
                                        id="Option3" />
                                </div>
                                <div>
                                    <strong class="tw-text-pretty tw-font-medium tw-text-gray-900">Candi</strong>
                                </div>
                            </label>
                        </div>
                    </fieldset>
                </details>
            </div>
        </div>
    </div>
</template>