<script setup>
import { ref, onMounted, watch } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function gotoPromotion() {
    router.push('/promotion-catering');
}
</script>

<template>
    <div
        class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12">
        <div class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md">
            <h2 class="tw-sm:text-3xl tw-text-2xl tw-leading-6 tw-font-bold tw-text-center tw-text-black tw-mb-4">
                Pembayaran Promosi
            </h2>
            <label class="tw-block tw-mt-8 tw-text-sm tw-font-medium tw-leading-6">
                Nama Catering
            </label>
            <p class="tw-sm:text-lg tw-text-base tw-text-left tw-text-black">
                Catering
            </p>
            <label class="tw-block tw-mt-4 tw-text-sm tw-font-medium tw-leading-6">
                Bukti Pembayaran Promosi Highlight
            </label>
            <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
                <label for="dropzone-file"
                    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100">
                    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
                        <svg class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3" />
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m9 11 2 2 4-4M12 15v-7" />
                        </svg>
                        <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                            <span class="tw-font-semibold">Click to upload</span> or drag
                            and drop
                        </p>
                        <p class="tw-text-xs tw-text-gray-500">PNG dan JPG(MAX. 2mb)</p>
                    </div>
                    <input id="dropzone-file" type="file" class="tw-hidden" />
                </label>
            </div>
            <div class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8">
                <button @click="gotoPromotion()" type="submit"
                    class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500">
                    Proses
                </button>
                <button @click="gotoPromotion()" type="button"
                    class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500">
                    Kembali
                </button>
            </div>
        </div>
    </div>
</template>