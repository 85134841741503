<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const inputs = ref([{ value: '' }]);

const addInput = () => {
    inputs.value.push({ value: '' });
};

const handleSubmit = () => {
    console.log(inputs.value);
};

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function gotoCateringHome() {
    router.push('/owner-catering');
}
</script>

<template>
    <div
        class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12">
        <div class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md">
            <h2 class="tw-sm:text-3xl tw-text-2xl tw-leading-6 tw-font-bold tw-text-center tw-text-black tw-mb-4">
                Buat Paket
            </h2>
            <form>
                <div>
                    <h3 class="tw-mb-3 tw-md:text-xl tw-text-sm">
                        Kategori Paket
                    </h3>
                    <select id="category"
                        class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500">
                        <option selected disabled class="tw-text-gray-300">
                            Pilih Kategori
                        </option>
                        <option value="">Ultah</option>
                        <option value="">Hajatan</option>
                        <option value="">Pernikahan</option>
                        <option value="">Arisan</option>
                    </select>
                </div>
                <div class="tw-mt-2">
                    <label for="name" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                        Nama Paket
                    </label>
                    <div class="tw-mt-2">
                        <input id="name" type="text"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                            required />
                    </div>
                </div>
                <div class="tw-mt-2">
                    <label for="price" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                        Harga Paket
                    </label>
                    <div class="tw-mt-2">
                        <input id="price" type="number"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                            required />
                    </div>
                </div>
                <div class="tw-flex tw-flex-row">
                    <div class="tw-mt-2 tw-mr-2 tw-w-1/2">
                        <label for="max" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Minimal Pilihan Menu
                        </label>
                        <div class="tw-mt-2">
                            <input id="max" type="number"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div class="tw-mt-2 tw-ml-2 tw-w-1/2">
                        <label for="min" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Maksimal Pilihan Menu
                        </label>
                        <div class="tw-mt-2">
                            <input id="min" type="number"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                </div>
                <div v-for="(input, index) in inputs" :key="index" class="my-2">
                    <label :for="'menu-' + (index + 1)" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                        Nama Paket {{ index + 1 }}
                    </label>
                    <div class="tw-mt-2">
                        <input type="text" v-model="input.value" :id="'menu-' + (index + 1)"
                            :name="'menu-' + (index + 1)"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                            required />
                    </div>
                </div>
                <div class="tw-flex tw-justify-end tw-w-full">
                    <button type="button" @click="addInput"
                        class="tw-mt-4 tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md">
                        Tambah Menu
                    </button>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8">
                    <button @click="gotoCateringHome()" type="submit"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500">
                        Submit
                    </button>
                    <button @click="gotoCateringHome()" type="button"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>