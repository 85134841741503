export default {
  install(app) {
    app.config.globalProperties.$numFormat = (key) => {
      return Number(key).toLocaleString().replaceAll(",", ".");
    };

    app.config.globalProperties.$formatRegion = (region) => {
      return region
        .toLowerCase()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ")
        .replace("Kabupaten", "Kab.");
    };
    app.config.globalProperties.$humanNumber = (n) => {
      let unit = "";
      if (n >= 1000000) {
        n = n / 1000000;
        unit = "jt";
      } else if (n >= 1000) {
        n = n / 1000;
        unit = "rb";
      }

      return `${Math.round(n * 10) / 10} ${unit}`;
    };
  },
};
