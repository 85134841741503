<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const swiperModule = [Pagination, Navigation];
const router = useRouter();
const token = ref(null);
const isDropdownCategory = ref(false);
const isFilterCategory = ref(false);
const isFilterPrice = ref(false);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function handleFilterCategory() {
    isFilterCategory.value = !isFilterCategory.value;
    isFilterPrice.value = false;
}

function handleFilterPrice() {
    isFilterPrice.value = !isFilterPrice.value;
    isFilterCategory.value = false;
}

function openDropdown() {
    isDropdownCategory.value = !isDropdownCategory.value;
}

function gotoAuth() {
    router.push('/auth');
}

function gotoHome() {
    router.push('/');
}
</script>

<template>
    <div class="tw-flex tw-flex-col">
        <nav
            class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow">
            <button @click="gotoHome"
                class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4">
                <img src="@/assets/caricatering.png" alt="Logo" class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12">
                <a href="#"
                    class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg">CariCatering.id</a>
            </button>
            <div class="tw-flex tw-items-center tw-space-x-4">
                <div class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
                    <button @click="openDropdown" class="tw-flex tw-items-center tw-text-gray-700">
                        Cari Apa?
                        <span class="tw-transition" :class="isDropdownCategory ? 'tw-rotate-180' : ''">
                            <svg class="tw-ml-1 tw-w-4 tw-h-4" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd"
                                    d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </button>
                    <div class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
                        :class="isDropdownCategory ? '' : 'tw-hidden'" style="z-index: 1;">
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 1</a>
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 2</a>
                        <a href="#" class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100">Option 3</a>
                    </div>
                </div>
                <a href="#" class="tw-text-gray-700 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Pusat Bantuan</a>
                <a href="#" class="tw-text-gray-700 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Syarat dan
                    Ketentuan</a>
                <button @click="gotoAuth"
                    class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded">
                    Masuk
                </button>
            </div>
        </nav>

        <div class="tw-w-full tw-py-8 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-space-y-8">
            <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6 tw-flex-col">
                <div class="tw-flex tw-items-center tw-space-x-6 tw-w-full">
                    <img src="@/assets/home2.jpg" alt="Logo" class="tw-w-32 tw-h-32 tw-rounded-lg tw-object-cover">
                    <div class="tw-flex-1 tw-hidden sm:tw-block md:tw-block lg:tw-block">
                        <h2 class="tw-text-xl tw-font-semibold">Delightful Dishes Catering</h2>
                        <p class="tw-text-sm tw-text-gray-600">Specializing in gourmet meals for all occasions</p>
                        <a href="/owner-home" class="tw-text-catering tw-underline tw-text-sm">
                            www.owner-home.com
                        </a>
                    </div>
                    <button
                        class="tw-flex tw-hidden sm:tw-flex md:tw-flex lg:tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-indigo-600 focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80">
                        <svg class="tw-w-5 tw-h-5 tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                clip-rule="evenodd" />
                        </svg>
                        Make a deal!
                    </button>
                </div>
                <div class="tw-flex sm:tw-hidden md:tw-hidden lg:tw-hidden tw-space-x-6 tw-w-full">
                    <div class="tw-flex-1 tw-mt-4">
                        <h2 class="tw-text-xl tw-font-semibold">Delightful Dishes Catering</h2>
                        <p class="tw-text-sm tw-text-gray-600">Specializing in gourmet meals for all occasions</p>
                        <a href="/owner-home" class="tw-text-catering tw-underline tw-text-sm">
                            www.owner-home.com
                        </a>
                    </div>
                </div>
                <button
                    class="tw-flex sm:tw-hidden md:tw-hidden lg:tw-hidden tw-mt-4 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-indigo-600 focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80">
                    <svg class="tw-w-5 tw-h-5 tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                            clip-rule="evenodd" />
                    </svg>
                    Make a deal!
                </button>
            </div>

            <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4">
                <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                    <h3 class="tw-font-semibold tw-mb-2">About Us</h3>
                    <p class="tw-text-sm tw-text-gray-600">Delightful Dishes Catering has been serving exquisite meals
                        for over 15 years. We pride ourselves on using fresh, locally-sourced ingredients and creating
                        memorable dining experiences for our clients.</p>
                </div>
                <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                    <h3 class="tw-font-semibold tw-mb-2">Specialties</h3>
                    <ul class="tw-text-sm tw-text-gray-600 tw-space-y-1">
                        <li>Corporate Events</li>
                        <li>Weddings</li>
                        <li>Birthday Parties</li>
                        <li>Holiday Celebrations</li>
                        <li>Cocktail Receptions</li>
                    </ul>
                </div>
                <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                    <h3 class="tw-font-semibold tw-mb-2">Contact Information</h3>
                    <ul class="tw-text-sm tw-text-gray-600 tw-space-y-1">
                        <li><i class="fas fa-map-marker-alt tw-text-red-500"></i> 123 Culinary Lane, Foodville</li>
                        <li><i class="fas fa-phone tw-text-red-500"></i> (555) 123-4567</li>
                        <li><i class="fas fa-envelope tw-text-red-500"></i> info@delightfuldishes.com</li>
                        <li><i class="fas fa-globe tw-text-red-500"></i> www.delightfuldishes.com</li>
                    </ul>
                </div>
            </div>

            <div>
                <h3 class="tw-font-semibold tw-mb-4">Featured Menu Packages</h3>
                <div
                    class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-4 tw-py-4 tw-py-4">
                    <div class="tw-relative tw-h-fit tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300">
                        <div @click="handleFilterCategory"
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition">
                            <span class="tw-text-sm tw-font-medium">Kategori</span>
                            <span class="tw-transition" :class="isFilterCategory ? 'tw-rotate-180' : ''">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="tw-h-4 tw-w-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            </span>
                        </div>
                        <div class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full" style="z-index: 1;"
                            v-show="isFilterCategory">
                            <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
                                <span class="tw-text-sm tw-text-gray-700"> 0 Selected </span>
                                <button type="button"
                                    class="tw-text-sm tw-text-gray-900 tw-underline tw-underline-offset-4">
                                    Reset
                                </button>
                            </header>
                            <ul class="tw-space-y-1 tw-border-t tw-border-gray-200 tw-p-4">
                                <li>
                                    <label for="FilterInStock" class="tw-inline-flex tw-items-center tw-gap-2">
                                        <input type="checkbox" id="FilterInStock"
                                            class="tw-size-5 tw-rounded tw-border-gray-300" />

                                        <span class="tw-text-sm tw-font-medium tw-text-gray-700"> In Stock (5+) dsadsa
                                            dasdas
                                            dsadsa </span>
                                    </label>
                                </li>
                                <li>
                                    <label for="FilterPreOrder" class="tw-inline-flex tw-items-center tw-gap-2">
                                        <input type="checkbox" id="FilterPreOrder"
                                            class="tw-size-5 tw-rounded tw-border-gray-300" />

                                        <span class="tw-text-sm tw-font-medium tw-text-gray-700"> Pre Order (3+) </span>
                                    </label>
                                </li>
                                <li>
                                    <label for="FilterOutOfStock" class="tw-inline-flex tw-items-center tw-gap-2">
                                        <input type="checkbox" id="FilterOutOfStock"
                                            class="tw-size-5 tw-rounded tw-border-gray-300" />

                                        <span class="tw-text-sm tw-font-medium tw-text-gray-700"> Out of Stock (10+)
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tw-relative tw-h-fit tw-w-full tw-rounded tw-border tw-border-gray-300">
                        <div @click="handleFilterPrice"
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition">
                            <span class="tw-text-sm tw-font-medium">Harga</span>
                            <span class="tw-transition" :class="isFilterPrice ? 'tw-rotate-180' : ''">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="tw-h-4 tw-w-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            </span>
                        </div>
                        <div class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full" style="z-index: 1;"
                            v-show="isFilterPrice">
                            <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
                                <span class="tw-text-sm tw-text-gray-700"> The highest price is $600 </span>
                                <button type="button"
                                    class="tw-text-sm tw-text-gray-900 tw-underline tw-underline-offset-4">
                                    Reset
                                </button>
                            </header>
                            <div class="tw-border-t tw-border-gray-200 tw-p-4">
                                <div class="tw-flex tw-justify-between tw-gap-4">
                                    <label for="FilterPriceFrom" class="tw-flex tw-items-center tw-gap-2">
                                        <span class="tw-text-sm tw-text-gray-600">Rp</span>
                                        <input type="number" id="FilterPriceFrom" placeholder="From"
                                            class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm" />
                                    </label>
                                    <label for="FilterPriceTo" class="tw-flex tw-items-center tw-gap-2">
                                        <span class="tw-text-sm tw-text-gray-600">Rp</span>
                                        <input type="number" id="FilterPriceTo" placeholder="To"
                                            class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4">
                    <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                        <div class="tw-h-48 tw-w-full">
                            <swiper class="swiper-backface-hidden" id="product_photos_swiper" :pagination="{
                dynamicBullets: true,
            }" :modules="swiperModule">
                                <swiper-slide>
                                    <img src="@/assets/home1.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home2.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home3.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="tw-text-sm tw-mt-4">Classic Dinner</div>
                        <div class="tw-text-lg tw-text-black">Paket 1</div>
                        <div class="tw-flex tw-flex-wrap tw-mt-1">
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                nasi goreng
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                cap cai
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                mie kecap
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                koloke
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                es campur
                            </div>
                        </div>
                        <p class="tw-text-gray-400 tw-mt-1">*pilihan 2 - 5 menu / paket</p>
                        <p class="tw-text-catering tw-font-semibold tw-mt-1">Rp20.000 / paket</p>
                    </div>
                    <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                        <div class="tw-h-48 tw-w-full">
                            <swiper class="swiper-backface-hidden" id="product_photos_swiper" :pagination="{
                dynamicBullets: true,
            }" :modules="swiperModule">
                                <swiper-slide>
                                    <img src="@/assets/home1.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home2.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home3.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="tw-text-sm tw-mt-4">Classic Dinner</div>
                        <div class="tw-text-lg tw-text-black">Paket 1</div>
                        <div class="tw-flex tw-flex-wrap tw-mt-1">
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                nasi goreng
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                cap cai
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                mie kecap
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                koloke
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                es campur
                            </div>
                        </div>
                        <p class="tw-text-gray-400 tw-mt-1">*pilihan 2 - 5 menu / paket</p>
                        <p class="tw-text-catering tw-font-semibold tw-mt-1">Rp20.000 / paket</p>
                    </div>
                    <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                        <div class="tw-h-48 tw-w-full">
                            <swiper class="swiper-backface-hidden" id="product_photos_swiper" :pagination="{
                dynamicBullets: true,
            }" :modules="swiperModule">
                                <swiper-slide>
                                    <img src="@/assets/home1.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home2.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home3.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="tw-text-sm tw-mt-4">Classic Dinner</div>
                        <div class="tw-text-lg tw-text-black">Paket 1</div>
                        <div class="tw-flex tw-flex-wrap tw-mt-1">
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                nasi goreng
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                cap cai
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                mie kecap
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                koloke
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                es campur
                            </div>
                        </div>
                        <p class="tw-text-gray-400 tw-mt-1">*pilihan 2 - 5 menu / paket</p>
                        <p class="tw-text-catering tw-font-semibold tw-mt-1">Rp20.000 / paket</p>
                    </div>
                    <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                        <div class="tw-h-48 tw-w-full">
                            <swiper class="swiper-backface-hidden" id="product_photos_swiper" :pagination="{
                dynamicBullets: true,
            }" :modules="swiperModule">
                                <swiper-slide>
                                    <img src="@/assets/home1.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home2.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="@/assets/home3.jpg" alt=""
                                        class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg" />
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="tw-text-sm tw-mt-4">Classic Dinner</div>
                        <div class="tw-text-lg tw-text-black">Paket 1</div>
                        <div class="tw-flex tw-flex-wrap tw-mt-1">
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                nasi goreng
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                cap cai
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                mie kecap
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                koloke
                            </div>
                            <div
                                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600">
                                es campur
                            </div>
                        </div>
                        <p class="tw-text-gray-400 tw-mt-1">*pilihan 2 - 5 menu / paket</p>
                        <p class="tw-text-catering tw-font-semibold tw-mt-1">Rp20.000 / paket</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>