<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function gotoCateringHome() {
    router.push('/owner-catering');
}
</script>

<template>
    <div
        class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12">
        <div class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md">
            <h2 class="tw-sm:text-4xl tw-text-3xl tw-leading-6 tw-font-bold tw-text-center tw-text-primary tw-mb-4">
                WORK WITH US
            </h2>
            <p class="tw-sm:text-lg tw-text-base tw-text-center tw-sm:mb-12 tw-mb-6 tw-text-gray-500">
                NOW, THAT'S SOME SMART THINKING.
            </p>
            <form>
                <label class="tw-block tw-mt-8 tw-text-sm tw-font-medium tw-leading-6">
                    Foto Logo Catering
                </label>
                <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
                    <label for="dropzone-file"
                        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100">
                        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
                            <svg class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3" />
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m9 11 2 2 4-4M12 15v-7" />
                            </svg>
                            <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                                <span class="tw-font-semibold">Click to upload</span> or drag
                                and drop
                            </p>
                            <p class="tw-text-xs tw-text-gray-500">PNG dan JPG(MAX. 2mb)</p>
                        </div>
                        <input id="dropzone-file" type="file" class="tw-hidden" />
                    </label>
                </div>
                <div class="tw-grid tw-sm:grid-cols-2 tw-md:gap-8 tw-gap-6 tw-text-slate-500">
                    <div>
                        <label for="name" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Nama Catering
                        </label>
                        <div class="tw-mt-2">
                            <input id="name" type="text"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div>
                        <label for="email" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Email Catering
                        </label>
                        <div class="tw-mt-2">
                            <input id="email" type="text"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div>
                        <label for="phone" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            No Whatsapp
                        </label>
                        <div class="tw-mt-2">
                            <input id="phone" type="text"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div>
                        <label for="message" class="tw-block tw-text-sm tw-font-medium tw-leading-6">Tentang
                            Kami</label>
                        <textarea id="message"
                            class="tw-block tw-p-2.5 tw-max-h-20 tw-min-h-[80px] tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-bottom-0 tw-rounded-lg tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-primary tw-focus:ring-2 tw-focus:ring-inset tw-transition-all tw-duration-500"></textarea>
                    </div>
                    <div>
                        <label for="address" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Alamat Catering
                        </label>
                        <div class="tw-mt-2">
                            <input id="address" type="text"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div>
                        <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">
                            Provinsi
                        </h3>
                        <select id="Budget"
                            class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500">
                            <option selected disabled class="tw-text-gray-300">
                                Pilih Provinsi
                            </option>
                            <option value="">Jawa Timur</option>
                            <option value="">Jawa Tengah</option>
                            <option value="">Jawa Barat</option>
                            <option value="">Bali</option>
                        </select>
                    </div>
                    <div>
                        <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">
                            Kabupaten / Kota
                        </h3>
                        <select id="Budget"
                            class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500">
                            <option selected disabled class="tw-text-gray-300">
                                Pilih Kabupaten / Kota
                            </option>
                            <option value="">Surabaya</option>
                            <option value="">Sidoarjo</option>
                            <option value="">Mojokerto</option>
                            <option value="">Malang</option>
                        </select>
                    </div>
                    <div>
                        <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">
                            Kecamatan
                        </h3>
                        <select id="Budget"
                            class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500">
                            <option selected disabled class="tw-text-gray-300">
                                Pilih Kecamatan
                            </option>
                            <option value="">Sukolila</option>
                            <option value="">Sukodono</option>
                            <option value="">Rungkut</option>
                            <option value="">Ngagel</option>
                        </select>
                    </div>
                    <div>
                        <label for="postal" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                            Kode Pos
                        </label>
                        <div class="tw-mt-2">
                            <input id="postal" type="number"
                                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                                required />
                        </div>
                    </div>
                    <div>
                        <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Layanan</h3>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-6">
                            <div class="tw-flex tw-items-center">
                                <input type="checkbox" id="other"
                                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary" />
                                <label for="other"
                                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500">
                                    Corporate Events
                                </label>
                            </div>
                            <div class="tw-flex tw-items-center">
                                <input type="checkbox" id="other"
                                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary" />
                                <label for="other"
                                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500">
                                    Weddings
                                </label>
                            </div>
                            <div class="tw-flex tw-items-center">
                                <input type="checkbox" id="other"
                                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary" />
                                <label for="other"
                                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500">
                                    Birthday Parties
                                </label>
                            </div>
                            <div class="tw-flex tw-items-center">
                                <input type="checkbox" id="other"
                                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary" />
                                <label for="other"
                                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500">
                                    Holiday Celebrations
                                </label>
                            </div>
                            <div class="tw-flex tw-items-center">
                                <input type="checkbox" id="other"
                                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary" />
                                <label for="other"
                                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500">
                                    Big Events
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8">
                    <button @click="gotoCateringHome()" type="submit"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500">
                        Submit
                    </button>
                    <button @click="gotoCateringHome()" type="button"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>