<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const outletId = ref(null);
const isPromotion = ref(true);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function closePromotion() {
    isPromotion.value = false;
}

function gotoOwnerHome() {
    router.push('/owner-home');
}

function gotoOwnerCatering() {
    router.push('/owner-catering');
}

function gotoPromotionCatering() {
    router.push('/promotion-catering');
}

function gotoPayment() {
    router.push('/payment/highlight/1');
}
</script>

<template>
    <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
        <aside class="tw-w-64 tw-bg-white tw-text-black">
            <nav>
                <div class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white">
                    <div class="tw-px-4 tw-py-6">
                        <div class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100">
                            <a href="#" class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50">
                                <img alt=""
                                    src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                                    class="tw-size-10 tw-rounded-full tw-object-cover" />

                                <div>
                                    <p class="tw-text-xs">
                                        <strong class="tw-block tw-text-black tw-font-medium">Eric Frusciante</strong>

                                        <span class="tw-text-black"> eric@frusciante.com </span>
                                    </p>
                                </div>
                            </a>
                        </div>
                        <ul class="tw-mt-6 tw-space-y-1">
                            <li>
                                <button @click="gotoOwnerHome()"
                                    class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                    Home
                                </button>
                            </li>
                            <li>
                                <button @click="gotoOwnerCatering()"
                                    class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                    Catering Saya
                                </button>
                            </li>
                            <li>
                                <details class="tw-group [&_summary::-webkit-details-marker]:tw-hidden" open>
                                    <summary
                                        class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                        <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                                        <span
                                            class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="tw-size-5"
                                                viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </summary>
                                    <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                                        <li>
                                            <button @click="gotoPromotionCatering()"
                                                class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-catering">
                                                Highlight Catering
                                            </button>
                                        </li>
                                        <li>
                                            <button href="#"
                                                class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                                Halaman Premium Catering
                                            </button>
                                        </li>
                                        <li>
                                            <button href="#"
                                                class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                                Foto dan Desain Catering
                                            </button>
                                        </li>
                                    </ul>
                                </details>
                            </li>
                            <li>
                                <button href="#"
                                    class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering">
                                    Akun
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </aside>
        <section v-if="isPromotion" class="tw-py-20 xl:tw-h-screen tw-w-full tw-flex tw-items-center tw-justify-center">
            <div class="tw-container">
                <h2 class="tw-text-3xl tw-font-bold tw-text-gray-950 tw-text-center">3 Langkah Promosi Katering</h2>
                <div class="tw-max-w-3xl tw-mx-auto tw-space-y-6 tw-mt-12">
                    <div
                        class="sm:tw-ps-0 tw-p-6 tw-rounded-xl tw-border tw-border-gray-200 tw-bg-white hover:tw-shadow-md tw-transition-all tw-duration-500">
                        <div
                            class="tw-flex sm:tw-flex-nowrap tw-flex-wrap tw-items-center tw-text-center sm:tw-text-start sm:tw-gap-0 tw-gap-3">
                            <div class="sm:tw-block tw-hidden">
                                <div
                                    class="tw--ms-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-lg tw-font-semibold tw-rounded-full tw-text-white tw-bg-blue-500">
                                    1</div>
                            </div>
                            <div class="tw-flex-shrink sm:tw-w-32 tw-mx-auto">
                                <img src="assets/images/process/img-5.png" class="tw-h-16 tw-mx-auto" alt="">
                            </div>
                            <div class="tw-flex-grow">
                                <h2 class="tw-text-2xl tw-font-bold tw-text-gray-950">Pilih Katering Anda</h2>
                                <p class="tw-text-base tw-font-medium tw-text-gray-500 tw-mt-2">Pilih katering anda yang
                                    akan dipromosikan.</p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="sm:tw-ps-0 tw-p-6 tw-rounded-xl tw-border tw-border-gray-200 tw-bg-white hover:tw-shadow-md tw-transition-all tw-duration-500">
                        <div
                            class="tw-flex sm:tw-flex-nowrap tw-flex-wrap tw-items-center tw-text-center sm:tw-text-start sm:tw-gap-0 tw-gap-3">
                            <div class="sm:tw-block tw-hidden">
                                <div
                                    class="tw--ms-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-lg tw-font-semibold tw-rounded-full tw-text-white tw-bg-blue-500">
                                    2</div>
                            </div>
                            <div class="tw-flex-shrink sm:tw-w-32 tw-mx-auto">
                                <img src="assets/images/process/img-6.png" class="tw-h-12 tw-mx-auto" alt="">
                            </div>
                            <div class="tw-flex-grow">
                                <h2 class="tw-text-2xl tw-font-bold tw-text-gray-950">Pembayaran</h2>
                                <p class="tw-text-base tw-font-medium tw-text-gray-500 tw-mt-2">Lakukan pembayaran
                                    promosi untuk melanjutkan proses promosi.</p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="sm:tw-ps-0 tw-p-6 tw-rounded-xl tw-border tw-border-gray-200 tw-bg-white hover:tw-shadow-md tw-transition-all tw-duration-500">
                        <div
                            class="tw-flex sm:tw-flex-nowrap tw-flex-wrap tw-items-center tw-text-center sm:tw-text-start sm:tw-gap-0 tw-gap-3">
                            <div class="sm:tw-block tw-hidden">
                                <div
                                    class="tw--ms-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-lg tw-font-semibold tw-rounded-full tw-text-white tw-bg-blue-500">
                                    3</div>
                            </div>
                            <div class="tw-flex-shrink sm:tw-w-32 tw-mx-auto">
                                <img src="assets/images/process/img-7.png" class="tw-h-14 tw-mx-auto" alt="">
                            </div>
                            <div class="tw-flex-grow">
                                <h2 class="tw-text-2xl tw-font-bold tw-text-gray-950">Promosi Catering Tampil</h2>
                                <p class="tw-text-base tw-font-medium tw-text-gray-500 tw-mt-2">Setelah pembayaran sudah
                                    dikonfirmasi admin, catering anda sudah siap dilihat banyak orang.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tw-flex tw-justify-center tw-mt-10">
                    <button @click="closePromotion()"
                        class="tw-py-2.5 tw-px-8 tw-rounded-full tw-text-lg tw-font-semibold tw-text-white tw-bg-blue-500 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-all tw-duration-500">Lanjutkan</button>
                </div>
            </div>
        </section>
        <section v-else class="tw-bg-white tw-bg-gray-100 tw-w-full">
            <div class="tw-container tw-py-6 tw-px-8">
                <div class="tw-flex tw-flex-row tw-justify-between tw-w-full">
                    <div class="tw-flex">
                        <img src="@/assets/caricatering.png" class="tw-mr-2 tw-h-8 sm:tw-h-8 md:tw-h-10 lg:tw-h-10">
                        <h1
                            class="tw-text-2xl tw-font-semibold tw-text-gray-800 tw-capitalize lg:tw-text-3xl tw-text-black">
                            Hallo, Deni</h1>
                    </div>
                    <!-- <button
                        class="tw-flex tw-items-center tw-justify-center tw-w-1/2 tw-mr-1 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-green-500 tw-rounded-lg hover:tw-bg-green-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-300 focus:tw-ring-opacity-80 tw-w-max">
                        <font-awesome-icon class="tw-text-white tw-pr-2" size="lg" :icon="['fas', 'plus']" />
                        <span class="tw-mx-1">Buat Promosi</span>
                    </button> -->
                </div>
                <div
                    class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4 tw-mt-6 tw-pb-12">
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-green-500 tw-rounded-lg hover:tw-bg-green-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-200 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Highlight</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-amber-500 tw-rounded-lg hover:tw-bg-amber-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-amber-200 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Proses Aktivasi</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button @click="gotoPayment()"
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Aktivasi Highlight</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button @click="gotoPayment()"
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Aktivasi Highlight</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button @click="gotoPayment()"
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Aktivasi Highlight</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-fit tw-min-h-fit">
                        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
                            <div class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg">
                                <img class="tw-object-cover tw-object-center tw-w-full tw-h-40" src="@/assets/home2.jpg"
                                    alt="avatar">
                                <div class="tw-px-3 tw-py-2">
                                    <p class="tw-text-gray-400 text-ellipsis">Corporate Events, Weddings, Birth
                                        Parties</p>
                                    <div
                                        class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis">
                                        Catering</div>
                                </div>
                                <div class="tw-px-3 tw-pb-2 tw-flex tw-flex-row tw-justify-between tw-w-full">
                                    <button @click="gotoPayment()"
                                        class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80">
                                        <span class="tw-mx-1">Aktivasi Highlight</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>