<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

</script>

<template>
    <section class="tw-bg-white">
        <div class="lg:tw-grid lg:tw-min-h-screen lg:tw-grid-cols-12">
            <section
                class="tw-relative tw-flex tw-h-32 tw-items-end tw-bg-gray-900 lg:tw-col-span-5 lg:tw-h-full tw-xl:col-span-6">
                <img alt="" src="@/assets/home1.jpg"
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-opacity-80" />

                <div class="tw-hidden lg:tw-relative lg:tw-block lg:tw-p-12">
                    <a class="tw-block tw-text-white" href="#">
                        <span class="tw-sr-only">Home</span>
                        <img src="@/assets/caricatering.png" alt="Logo"
                            class="tw-h-16 sm:tw-h-16 md:tw-h-20 lg:tw-h-20">
                    </a>

                    <h2 class="tw-mt-6 tw-text-2xl tw-font-bold tw-text-white sm:tw-text-3xl md:tw-text-4xl">
                        Selamat Datang
                    </h2>

                    <p class="tw-mt-4 tw-leading-relaxed tw-text-white/90">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi nam dolorum aliquam,
                        quibusdam aperiam voluptatum.
                    </p>
                </div>
            </section>

            <main
                class="tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-8 sm:tw-px-12 lg:tw-col-span-7 lg:tw-px-20 lg:tw-py-12 tw-xl:col-span-6">
                <div class="tw-w-full">
                    <div class="tw-relative tw--mt-16 tw-block lg:tw-hidden">
                        <a class="tw-inline-flex tw-size-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-blue-600 sm:tw-size-20"
                            href="#">
                            <span class="tw-sr-only">Home</span>
                            <img src="@/assets/caricatering.png" alt="Logo"
                                class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12">
                        </a>

                        <h2 class="tw-mt-6 tw-text-2xl tw-font-bold tw-text-gray-900 sm:tw-text-3xl">
                            Selamat Datang
                        </h2>

                        <p class="tw-mt-4 tw-leading-relaxed tw-text-gray-500">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi nam dolorum aliquam,
                            quibusdam aperiam voluptatum.
                        </p>
                    </div>

                    <form action="#" class="tw-mt-8 tw-grid tw-grid-cols-6 tw-gap-6">
                        <div class="tw-col-span-6 sm:tw-col-span-3">
                            <label for="FirstName" class="tw-block tw-text-sm tw-font-medium tw-text-catering">
                                First Name
                            </label>

                            <input type="text" id="FirstName" name="first_name"
                                class="tw-mt-1 tw-w-full tw-rounded-md tw-border-gray-200 tw-bg-white tw-text-sm tw-text-catering tw-shadow-sm" />
                        </div>

                        <div class="tw-col-span-6 sm:tw-col-span-3">
                            <label for="LastName" class="tw-block tw-text-sm tw-font-medium tw-text-catering">
                                Last Name
                            </label>

                            <input type="text" id="LastName" name="last_name"
                                class="tw-mt-1 tw-w-full tw-rounded-md tw-border-gray-200 tw-bg-white tw-text-sm tw-text-catering tw-shadow-sm" />
                        </div>

                        <div class="tw-col-span-6">
                            <label for="Email" class="tw-block tw-text-sm tw-font-medium tw-text-catering">
                                Email
                            </label>

                            <input type="email" id="Email" name="email"
                                class="tw-mt-1 tw-w-full tw-rounded-md tw-border-gray-200 tw-bg-white tw-text-sm tw-text-catering tw-shadow-sm" />
                        </div>

                        <div class="tw-col-span-6">
                            <label for="Password" class="tw-block tw-text-sm tw-font-medium tw-text-catering">
                                Password
                            </label>

                            <input type="password" id="Password" name="password"
                                class="tw-mt-1 tw-w-full tw-rounded-md tw-border-gray-200 tw-bg-white tw-text-sm tw-text-catering tw-shadow-sm" />
                        </div>

                        <div class="tw-col-span-6">
                            <label for="MarketingAccept" class="tw-flex tw-gap-4">
                                <input type="checkbox" id="MarketingAccept" name="marketing_accept"
                                    class="tw-h-5 tw-w-5 tw-rounded-md tw-border-gray-200 tw-bg-white tw-shadow-sm" />

                                <span class="tw-text-sm tw-text-catering">
                                    I want to receive emails about events, product updates, and company announcements.
                                </span>
                            </label>
                        </div>

                        <div class="tw-col-span-6">
                            <p class="tw-text-sm tw-text-gray-500">
                                By creating an account, you agree to our
                                <a href="#" class="tw-text-catering tw-underline">terms and conditions</a> and
                                <a href="#" class="tw-text-catering tw-underline">privacy policy</a>.
                            </p>
                        </div>

                        <div class="tw-col-span-6 sm:tw-flex sm:tw-items-center sm:tw-gap-4">
                            <button
                                class="tw-inline-block tw-shrink-0 tw-rounded-md tw-border tw-border-catering tw-bg-catering tw-px-12 tw-py-3 tw-text-sm tw-font-medium tw-text-white tw-transition hover:tw-bg-transparent hover:tw-text-catering focus:tw-outline-none focus:tw-ring active:tw-text-gray-500">
                                Create an account
                            </button>

                            <p class="tw-mt-4 tw-text-sm tw-text-gray-500 sm:tw-mt-0">
                                Already have an account?
                                <a href="#" class="tw-text-catering tw-underline">Log in</a>.
                            </p>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    </section>
</template>